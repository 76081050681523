//引入
import {JSEncrypt} from 'jsencrypt'

/**
 * 加密
 * @param param
 * @returns {string | false}
 */
export function passwordEncryption (param) {
    // 后台给的公钥
    let publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC7auZjaYf3G4KMUD/jbplwCFYW57+/K4jyFGF6QIHwYLt39AM8/jhjALVKzTZicL621HDLZZaPxJvmybuNwCxDqvVh5OtuGJjSohnkU/KsW4kLMCEjSaSWfOfju/4tIx/6E3XQk+eZSP4VunFVNf3CzDR13EF7NU7R4gBir2QwPwIDAQAB'
    let encryptor = new JSEncrypt() // 新建JSEncrypt对象
    encryptor.setPublicKey(publicKey) // 设置公钥
    return encryptor.encrypt(param)
}
